import * as React from "react";
import { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  useNotify,
  useUpdateMany,
  useListContext,
  Confirm,
  Button,
  BulkDeleteButton,
  DeleteButton,
  useRefresh,
  Create,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  required,
  FunctionField,
  DateField,
} from "react-admin";
import { Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Box, Link } from '@mui/material';
import { Warning } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import AutocompleteInput from './components/BaseAutocompleteInput';
import DisabledReasonInput from './components/DisabledReasonInput';

const filters = [  
<ReferenceInput label="Store Name" source="storeId" reference="stores" alwaysOn>
    <SelectInput optionText="storeName" validate={[required()]} />
</ReferenceInput>
];

const reasonRenderer = (reasonTitle, reasonDescription) => {
  return reasonTitle ? (reasonDescription ? `${reasonTitle}: ${reasonDescription}` : reasonTitle) : '';
}

const UpdatedProductList = ({ data }) => {
  return data.length
    ? (
      <dd>
        <ol>
          {data.map((item) => (
            <li key={item.productName}>
              <Link href={`#/store_products/${item.id}/show`}>{item.productName}</Link>
            </li>
          ))}
        </ol>
      </dd>
    )
    : (
      <dd>None</dd>
    );
}

const UpdatedDialog = ({ data, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Dialog open={Boolean(data)} maxWidth="md">
      <DialogTitle>Options impacted products</DialogTitle>
      <DialogContent>
        <Tabs value={tabIndex} onChange={(ev, v) => setTabIndex(v)}>
          <Tab label="Option impacted products" />
          <Tab label="Impacted products" />
        </Tabs>
        {tabIndex
          ? (
            <Box>
              {(data ?? []).map((item) => (
                <dl key={item.storeSupplyItemId}>
                  <dt>{`Supply Item Id: ${item.storeSupplyItemId}`}</dt>
                  <UpdatedProductList data={item.updatedProducts} />
                </dl>
              ))}
            </Box>
          )
          : (
            <Box>
              {(data ?? []).map((item) => (
                <dl key={item.storeSupplyItemId}>
                  <dt>{`Supply Item Id: ${item.storeSupplyItemId}`}</dt>
                  <UpdatedProductList data={item.optionUpdatedProducts} />
                </dl>
              ))}
            </Box>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus label="Got it"><CheckIcon /></Button>
      </DialogActions>
    </Dialog>
  );
};

const BulkEnableButton = (props) => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [doneList, setDoneList] = useState(null);
  const [updateMany, { isLoading }] = useUpdateMany(
      "store_supply_items",
      { ids: selectedIds,
        data: {enabled: true },
      },
      {
        onSuccess: (data, loading, loaded) => {
          setDoneList(data);
        },
        onError: (error) =>
          notify("Error: Supply items are not updated", { type: "warning" })
      }
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const refresh = useRefresh();
  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  const handleCloseUpdatedDialog = () => {
    setDoneList(null);
    refresh();
  }

  return (
    <>
      <Button
        {...props}
        label="Enable"
        data={{ enabled: true }}
        onClick={handleClick}
        disabled={isLoading}>
        <CheckIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Enable"
        content="This action will cause a series of updates corresponding to product options’ enablement and stock status.The out of stock products that only use this item, will remain out of stock. Are you sure you want to enable these supply items?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <UpdatedDialog data={doneList} onClose={handleCloseUpdatedDialog} />
    </>
  );
};

const BulkDisableButton = (props) => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const [doneList, setDoneList] = useState(null);
  const [reasonType, setReasonType] = useState('OUT_OF_STOCK');
  const [reasonDescription, setReasonDescription] = useState('');
  const [updateMany, { isLoading }] = useUpdateMany(
    "store_supply_items",
      { 
        ids: selectedIds,
        data: { 
          enabled: false,
          resourceStatusInformation: {
            reasonType: reasonType,
            reasonDescription: reasonDescription
          }
        },
      },
      {
        onSuccess: (data, loading, loaded) => {
          setDoneList(data);
        },
        onError: (error) =>
          notify("Error: Supply items are not updated", { type: "warning" })
      }
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  const handleCloseUpdatedDialog = () => {
    setDoneList(null);
    refresh();
  }
  const handleReasonTypeChange = (event) => {
    setReasonType(event.target.value);
  };
  const handleReasonDescriptionChange = (event) => {
    setReasonDescription(event.target.value);
  };

  const content = 
    <>
      <div>This action will cause a series of updates corresponding to product options’ enablement and stock status. The out of stock products that only use this item will remain out of stock. Are you sure you want to enable these supply items?</div>
      <br />
      <DisabledReasonInput 
        reasonType={reasonType} 
        setReasonType={setReasonType} 
        reasonDescription={reasonDescription} 
        setReasonDescription={setReasonDescription} 
      />
    </>

  return (
    <>
      <Button
          {...props}
          label="Disable"
          data={{ enabled: false }}
          onClick={handleClick}
          disabled={isLoading}>
        <CloseIcon/>
      </Button>
      <Confirm
          isOpen={open}
          loading={isLoading}
          title="Disable"
          content={content}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
      />
      <UpdatedDialog data={doneList} onClose={handleCloseUpdatedDialog} />
    </>
  );
};

const BulkActionButtons = (props) => {
  return (
    <>
      <BulkEnableButton />
      <BulkDisableButton />
      <BulkDeleteButton />
    </>
  );
};

export const StoreSupplyItemList = (props) => {
  return (
    <>
      <div className="alert alert-warning">
        <aside>
          <Warning />
        </aside>
        <article>
          <p>Enabling/disabling store supply will impact product and product options enablement</p>
          <ul>
            <li>Disabling store supply will disable corresponding product options. The product will be disabled too if it doesn't have any available production options.</li>
            <li>Enabling store supply will enable corresponding product options. The product will be enabled if it was disabled.</li>
          </ul>
        </article>
      </div>
      <List
        {...props} sort={{field:'', order:''}}
        filters={filters}
        filterDefaultValues={{ storeId: -1 }}
        pagination={false}>
        <Datagrid bulkActionButtons={<BulkActionButtons />}>
          <TextField label="Supply Item Name" source="supplyItemName" />
          <BooleanField source="enabled" />
          <FunctionField label="Disabled reason"
            render={
                record => {
                    var reasonTitle = record.resourceStatusInformation?.reasonTitle;
                    var reasonDescription = record.resourceStatusInformation?.reasonDescription;
                    return reasonRenderer(reasonTitle, reasonDescription);
                }
            } />
          <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
          <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};

export const StoreSupplyItemCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <ReferenceInput sort={{ field: '', order: 'ASC' }} source="supplyItemId" reference="supply_items_metadata">
              <AutocompleteInput label="Supply Item" optionText="supplyItemName" validate={[required()]} />
          </ReferenceInput>
          <BooleanInput source="enabled"/>
          <ReferenceInput required label="Store Name" source="storeId" reference="stores" >
              <SelectInput optionText="storeName" validate={[required()]}/>
          </ReferenceInput>
      </SimpleForm>
  </Create>
);
