import React, { useCallback, useMemo } from 'react';
import {
    Show,
    Datagrid,
    TextField,
    DateField,
    ArrayField,
    ShowButton,
    TextInput,
    SelectInput,
    SimpleShowLayout,
    required,
    useRecordContext,
    Link,
    useListContext,
    FunctionField,
    SingleFieldList,
    DateTimeInput,
    useInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { get, unset } from 'lodash';
import { Chip } from '@mui/material';
import { JsonField } from "react-admin-json-view";
import { BluehillPagination } from './bluehillPagination';
import { BaseList } from './components/BaseList';


const onSubmit = (values) => {
    // console.log(values);
};

const TYPES = [
    {
        id: 'ROBOT',
        name: 'Robot',
        'subobject.type': [
            { id: 'ROBOT_PROGRAM', name: 'Program' },
            { id: 'ROBOT_TASK', name: 'Task' },
            { id: 'ROBOT_ACTION', name: 'Action' },
            { id: 'ROBOT_ATTRIBUTE', name: 'Attribute' },
            { id: 'ROBOT_ORDER_ITEM', name: 'Item' },
            { id: 'ROBOT_DIRECTIVE', name: 'Directive' },
            { id: 'ROBOT_TROUBLE_TICKET', name: 'Trouble Ticket' },
            { id: 'ROBOT_IO_CONTROL', name: 'IO Control' },
        ],
        operationType: [
            {
                id: 'ROBOT_ORDER',
                name: 'Order',
                operationSubType: [
                    { id: 'CREATE_ROBOT_ORDER', name: 'Create Order' },
                    { id: 'CREATE_ROBOT_ORDER_ITEM', name: 'Create Order Item' },
                    { id: 'WHOLE_MILK_REFILL', name: 'Whole Milk Refill' },
                    { id: 'OAT_MILK_REFILL', name: 'Oat Milk Refill' },
                ]
            },
            { id: 'ROBOT_OPERATION', name: 'Operation' },
            { id: 'ROBOT_IO_OPERATION', name: 'IO Operation' },
            { id: 'ROBOT_MAINTENANCE', name: 'Maintennace' },
            { id: 'ROBOT_TROUBLE_TICKET', name: 'Trouble Ticket' },
        ]
    },
    {
        id: 'ROBOT_CONFIGURATION',
        name: 'Robot Configuration',
        operationType: [
            {
                id: 'ROBOT_ACTION_MGMT',
                name: 'Action Management',
                operationSubType: [
                    { id: 'CREATE_PROGRAM', name: 'Create Program' },
                    { id: 'UPDATE_PROGRAM', name: 'Update Program' },
                    { id: 'DELETE_PROGRAM', name: 'Delete Program' },
                    { id: 'CREATE_TASK', name: 'Create Task' },
                    { id: 'UPDATE_TASK', name: 'Update Task' },
                    { id: 'DELETE_TASK', name: 'Delete Task' },
                    { id: 'APPLY_ACTION_OFFSET', name: 'Apply Action Offset' },
                    { id: 'CHANGE_ACTION_DURATION', name: 'Change Action Duration' },
                    { id: 'ACTION_OFFSET_APPLIED', name: 'Action Offset Applied' },
                    { id: 'ACTION_DURATION_CHANGED', name: 'Action Duration Changed' },
                    { id: 'CREATE_ROBOT_ATTRIBUTE', name: 'Create Attribute' },
                    { id: 'UPDATE_ROBOT_ATTRIBUTE', name: 'Update Attribute' },
                    { id: 'DELETE_ROBOT_ATTRIBUTE', name: 'Delete Attribute' },
                ],
            },
        ],
    },
    {
        id: 'STORE',
        name: 'Store',
        'subobject.type': [
            { id: 'STORE_SUPPLY_ITEM', name: 'Store Supply Item' }
        ],
        operationType: [
            {
                id: 'STORE', name: 'Store',
                operationSubType: [
                    { id: 'CREATE_STORE', name: 'Create Store' },
                    { id: 'UPDATE_STORE', name: 'Update Store' },
                    { id: 'DELETE_STORE', name: 'Delete Store' },
                ]
            },
            {
                id: 'STORE_SUPPLY_ITEM',
                name: 'Store Supply Item',
                operationSubType: [
                    { id: 'UPDATE_STORE_SUPPLY', name: 'Update Store supply' },
                ]
            },
            {
                id: 'STORE_PRODUCT',
                name: 'Store Product',
                operationSubType: [
                    { id: 'UPDATE_STORE_PRODUCT_ATTRIBUTES', name: 'Update Store Product Attributes'},
                    { id: 'CREATE_STORE_PRODUCT', name: 'Create Store Product' },
                    { id: 'UPDATE_STORE_PRODUCT', name: 'Update Store Product' },
                    { id: 'DELETE_STORE_PRODUCT', name: 'Delete Store Product' },
                ]
            },
        ],
    },
    {
        id: 'PRODUCT_METADATA',
        name: 'Product Metadata',
        'subobject.type': [
            { id: 'DISTINCT_PRODUCT_ID', name: 'Distinct Product ID' }
        ],
        operationType: [
            {
                id: 'PRODUCT_METADATA',
                name: 'Product Metadata',
                operationSubType: [
                    { id: 'CREATE_PRODUCT_METADATA', name: 'Create Product Metadata' },
                    { id: 'UPDATE_PRODUCT_METADATA', name: 'Update Product Metadata' },
                    { id: 'DELETE_PRODUCT_METADATA', name: 'Delete Product Metadata' },
                ]
            },
        ],
    },
    {
        id: 'PRODUCT_GROUP',
        name: 'Product Group',
        operationType: [
            {
                id: 'PRODUCT_GROUP',
                name: 'Product Group',
                operationSubType: [
                    { id: 'CREATE_PRODUCT_GROUP', name: 'Create Product Group' },
                    { id: 'UPDATE_PRODUCT_GROUP', name: 'Update Product Group' },
                    { id: 'DELETE_PRODUCT_GROUP', name: 'Delete Product Group' },
                ]
            },
        ],
    },
];

function ChainableSelectInput({ subFields = [], parentField = [], rootOptions = TYPES, ...props }) {
    const { filterValues } = useListContext();
    const form = useFormContext();
    const parentValue = parentField.length ? get(filterValues, parentField[parentField.length - 1].split('.')) : null;
    const choices = useMemo(() => {
        if (!parentField.length) {
            return rootOptions;
        }
        const path = [...parentField, props.source];
        return parentField.reduce((result, key, index) => {
            const v = get(filterValues, key.split('.'));
            return result.find(type => type.id === v)?.[path[index + 1]] || [];
        }, rootOptions);
    }, [parentField, props.source, rootOptions, filterValues]);

    const onChange = useCallback((event) => {
        form.setValue(props.source, event.target.value);
        subFields.forEach(subField => {
            unset(filterValues, subField.split('.')[0]);
            form.setValue(subField, null);
        });
    }, [filterValues, form, props.source, subFields]);

    const value = get(filterValues, props.source);

    return !parentField.length || (parentValue && choices.length ) ? (
        <SelectInput
            {...props}
            choices={choices}
            value={value}
            onChange={onChange}
        />
    ) : null;
}

function TimestampInput(props) {
    const {
        field,
    } = useInput(props);
    return (
        <DateTimeInput
            label={props.label}
            {...field}
            onChange={(ev) => {
                const ts = Date.parse(ev.target.value);
                field.onChange(ts);
            }}
        />
    );
}

const filters = [
    <TextInput label="Object ID" source="object.id" alwaysOn />,
    <ChainableSelectInput
        label="Object Type"
        source="object.type"
        choices={TYPES}
        validate={[required()]}
        alwaysOn
        subFields={['subobject.type', 'operationType', 'operationSubType']}
    />,
    <ChainableSelectInput label="Object Sub Type" source="subobject.type" parentField={["object.type"]} alwaysOn />,
    <ChainableSelectInput label="Operation Type" source="operationType" parentField={["object.type"]} subFields={['operationSubType']} alwaysOn />,
    <ChainableSelectInput label="Operation Sub Type" source="operationSubType" parentField={['object.type', "operationType"]} alwaysOn />,
    <TimestampInput label="Start Time" source="startTime" alwaysOn />,
    <TimestampInput label="End Time" source="endTime" alwaysOn />,
];

export const OperationHistoryList = props => {
    const now = new Date();
    return (
        <BaseList {...props} resource="operation_history_summary" sort={{field:'', order:''}}
            filters={filters} filterDefaultValues={{ object: { id: 0, type: 'ROBOT' }, startTime: new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf() }}
            pagination={<BluehillPagination />} onSubmit={onSubmit}>
            <Datagrid>
                <TextField source="operationType" style={{ wordBreak: 'break-word' }} />
                <TextField source="operationSubType" style={{ wordBreak: 'break-word' }} />
                <TextField source="object.id" label="Object ID" />
                <TextField source="object.type" label="Object Type" />
                <TextField source="subObject.id" label="Subobject ID" />
                <TextField source="subObject.type" label="Subobject Type" />
                <TextField source="operatorInfo.name" label="Operator" />
                <DateField source="createdTime" showTime />
                <ShowButton resource="operation_history" />
            </Datagrid>
        </BaseList>
    );
};

export const OperationHistoryShowSummary = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <JsonField source="payload" label="" />
            </SimpleShowLayout>
        </Show>
    );
};

function TaskLink() {
    const record = useRecordContext();

    return (
        <Link to={`/robot_tasks/${record.id}/show`} variant="body2">{record.id}</Link>
    );
}

function TaskField() {
    const record = useRecordContext();

    return record.operationType === 'ROBOT_ORDER' || record.operationSubType === 'CREATE_ROBOT_ORDER_ITEM'
        ? (
            <ArrayField label="Tasks" source="payload.directive.payload.tasks">
                <Datagrid bulkActionButtons={false}>
                    <TaskLink />
                    <TextField source="taskKey" />
                    <JsonField source="variables" reactJsonOptions={{ enableClipboard: false, name: 'payload.directive.payload.tasks' }} />
                </Datagrid>
            </ArrayField>
        )
        : null;
}

export const OperationHistoryShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="payload.directive.payload.ownerName" label="Owner Name" />
                <TextField source="payload.directive.payload.displayName" label="Display Name" />
                <ArrayField source="payload.directive.payload.context.attributeOptions" label="Attribute Options">
                    <SingleFieldList linkType={false}>
                        <FunctionField
                            render={(record) => (
                                <Chip label={`${record.attributeName} - ${record.optionName}`} sx={{ marginRight: '0.5em' }} />
                            )}
                        />
                    </SingleFieldList>
                </ArrayField>
                <TextField source="operationHistoryId" />
                <TextField source="operationType" />
                <TextField source="operationSubType" />
                <TextField source="object.id" />
                <TextField source="object.type" />
                <TextField source="subObject.id" label="Subobject ID" />
                <TextField source="subObject.type" label="Subobject Type" />
                <TextField source="operatorInfo.id" />
                <TextField source="operatorInfo.name" />
                <TextField source="operatorInfo.type" />
                <DateField source="createdTime" showTime />
                <TaskField />
                <JsonField source="payload" reactJsonOptions={{ name: 'payload' }} />
            </SimpleShowLayout>
        </Show>
    );
};