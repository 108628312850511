import React, { useMemo, useState } from 'react';
import {
    List,
    Show,
    Datagrid,
    BooleanField,
    TextField,
    ReferenceField,
    ReferenceArrayField,
    DeleteButton,
    EditButton,
    ShowButton,
    BulkUpdateButton,
    Edit,
    Create,
    useCreateController,
    SimpleForm,
    BooleanInput,
    NullableBooleanInput,
    TextInput,
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    FormDataConsumer,
    SimpleShowLayout,
    useRecordContext,
    CloneButton,
    SaveButton,
    Toolbar,
    required,
    Labeled,
    useListContext,
    BulkUpdateWithConfirmButton,
    DateField,
    FunctionField,
} from 'react-admin';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useWatch } from 'react-hook-form';
import { JsonInput, JsonField } from "react-admin-json-view";

import { BluehillPagination } from './bluehillPagination';

import SortableIterator from './components/SortableIterator';
import AutocompleteInput from './components/BaseAutocompleteInput';
import DisabledReasonInput from './components/DisabledReasonInput';

const filters = [
    <TextInput label="Robot group" source="robotGroupKey" alwaysOn />,
    <TextInput label="Program key" source="partialProgramKey" alwaysOn />,
];

export const RobotGroupProgramList = props => (
    <List {...props} 
        perPage={50} sort={{ field: '', order: '' }}
        filters={filters}
        filterDefaultValues={{ robotGroupKey: 'ALL' }}
        pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="robotGroupKey" />
            <TextField source="programKey" style={{ wordBreak: 'break-word' }} />
            <DateField source="createdTime" showTime />
            <DateField source="updatedTime" showTime />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

export const RobotGroupProgramShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="robotGroupKey" />
                <TextField source="programKey" />
                <DateField source="createdTime" showTime />
                <DateField source="updatedTime" showTime />
                <JsonField source="stateMachineData" />
            </SimpleShowLayout>
        </Show>
    );
};

export const RobotGroupProgramCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm >
                <TextInput source="robotGroupKey" />
                <ReferenceInput source="programKey" reference="robot_program_keys" fullWidth>
                    <AutocompleteInput optionText="programKey"
                        queryFields={['programKey']}
                        filterToQuery={searchText => ({ partialProgramKey: searchText })}
                        shouldRenderSuggestions={searchText => searchText.trim().length > 1} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const RobotGroupProgramEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextField source="robotGroupKey" />
            <ReferenceInput source="programKey" reference="robot_program_keys" fullWidth>
                <AutocompleteInput optionText="programKey"
                    queryFields={['programKey']}
                    filterToQuery={searchText => ({ partialProgramKey: searchText })}
                    shouldRenderSuggestions={searchText => searchText.trim().length > 1} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
