import * as React from "react";
import { useState } from 'react';
import {
    Labeled,
    List,
    Show,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    BulkUpdateButton,
    Edit,
    Create,
    SimpleForm,
    BooleanInput,
    TextInput,
    SelectInput,
    ReferenceInput,
    SimpleShowLayout,
    useEditController,
    FunctionField,
    useDataProvider,
    useNotify,
    useRecordContext,
    Button,
    Confirm,
    BulkUpdateWithConfirmButton,
    DateField,
    BooleanField,
} from 'react-admin';
import { Visibility, VisibilityOff, LibraryAdd, Refresh, ExpandMore } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, Menu, MenuItem, Button as MUIButton } from '@mui/material';
import { Grid } from '@mui/material';
import { JsonInput } from "react-admin-json-view";
import { JsonField } from "react-admin-json-view";
import { ExpandableContent } from "./components/ExpandableContent";
import DisabledReasonInput from './components/DisabledReasonInput';

const filters = [
    <TextInput label="Robot ID" source="robotId" alwaysOn />,
    <BooleanInput label="Enabled" source="enabled" />,
];

const reasonRenderer = (reasonStatusInformation) => {
    const reasonTitle = reasonStatusInformation?.reasonTitle;
    const reasonDescription = reasonStatusInformation?.reasonDescription;
    return reasonTitle ? (reasonDescription ? `${reasonTitle}: ${reasonDescription}` : reasonTitle) : '';
}

const DisabledReasonField = ({ label }) => {
    const record = useRecordContext();
    if (!record) return null;
    return <FunctionField label={label}
        render={record => reasonRenderer(record?.resourceStatusInformation)} />;
}

const CupDispenserReset = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [open, setOpen] = useState(false);

    const resetOperation = record?.properties?.operations?.find(item => item.id === 'reset');

    async function onConfirm() {
        try {
            await dataProvider.create(`robots/${record.robotId}/ios/${record.ioKey}/operations/${resetOperation.id}/executions`, {
                data: {}
            });
            notify('Robot reset!');
        } catch (error) {
            console.error(error);
            notify('Robot reset failed!');
        } finally {
            setOpen(false);
        }
    }

    if (!record.ioKey.startsWith('cupDispenser.') ||
        !record?.properties?.operations?.length) {
        return null;
    }

    return resetOperation && (
        <>
            <Button label="Reset" onClick={() => setOpen(true)}>
                <Refresh />
            </Button>
            <Confirm
                isOpen={open}
                title={resetOperation.confirmationInfo.title}
                content={resetOperation.confirmationInfo.description}
                onClose={() => setOpen(false)}
                onConfirm={() => onConfirm()}
            />
        </>
    );
}

const Operations = ({ record }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [currentOperation, setCurrentOperation] = useState(null);
    const [menuOpen, setMenuOpen] = useState(null);

    async function onConfirm(op) {
        try {
            await dataProvider.create(`robots/${record.robotId}/ios/${record.ioKey}/operations/${op.id}/executions`, {
                data: {}
            });
            notify('Operation sent!');
        } catch (error) {
            console.error(error);
            notify(error.message);
        } finally {
            setCurrentOperation(null);
        }
    }

    if (!record?.properties?.operations?.length) {
        return null;
    }

    return (
        <>
            {record?.properties?.operations?.length > 1
                ? (
                    <>
                        <MUIButton
                            label="Operations"
                            aria-controls="operations-menu"
                            aria-haspopup="true"
                            onClick={({ currentTarget }) => setMenuOpen(currentTarget)}
                        >
                            Operations<ExpandMore />
                        </MUIButton>
                        <Menu
                            id="operations-menu"
                            anchorEl={menuOpen}
                            open={Boolean(menuOpen)}
                            onClose={() => setMenuOpen(null)}
                        >
                            {record?.properties?.operations.map(operation => (
                                <MenuItem
                                    key={operation.id}
                                    onClick={() => {
                                        setMenuOpen(null);
                                        setCurrentOperation(operation);
                                    }}
                                >
                                    {operation.id}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )
                : (
                    <Button label={record?.properties?.operations[0]?.id} onClick={() => setCurrentOperation(record?.properties?.operations[0])} />
                )
            }
            {currentOperation && (
                <Confirm
                    isOpen={Boolean(currentOperation)}
                    title={currentOperation.confirmationInfo.title}
                    content={currentOperation.confirmationInfo.description}
                    onClose={() => setCurrentOperation(null)}
                    onConfirm={() => onConfirm(currentOperation)}
                />
            )}
        </>
    );
};

const EditRobotIOButton = () => {
    const record = useRecordContext();
    if (!record.reversed) {
        return <EditButton record={record} />;
    } else {
        return <div />;
    }
};

const ShowRobotIOButton = () => {
    const record = useRecordContext();
    if (!record.reversed) {
        return <ShowButton record={record} />;
    } else {
        return <div />;
    }
};


const BulkCloneButton = ({ filterValues, selectedIds, ...props }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const fetchSelectedRows = async () => {
        const requests = selectedIds.map((id) => {
            return dataProvider.getOne('robot_ios', { id }).then((response) => response.data);
        });
        return Promise.all(requests);
    };
    async function onSubmit({ robotId }) {
        try {
            const selectedRows = await fetchSelectedRows();
            const bulkCreateData = selectedRows.map((row) => ({
                robotId: row.robotId,
                ioKey: row.ioKey,
            }));
            await dataProvider.create('robot_ios_bulkclone_executions', {
                data: {
                    targetRobotId: robotId,
                    robotIos: bulkCreateData,
                }
            });
            notify('Robot IO cloned!');
            setOpen(false);
        } catch (e) {
            notify(e.message);
        }
    }
    return (
        <>
            <Button onClick={() => setOpen(true)} label="Clone" >{<LibraryAdd />}</Button>
            <Dialog open={open} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle id="simple-dialog-title">Clone to new robot</DialogTitle>
                <DialogContent>
                    <Create {...props}>
                        <SimpleForm onSubmit={onSubmit}>
                            <ReferenceInput sort={{field:'', order:'ASC'}} label="Target Robot" source="robotId" reference="robots">
                                <SelectInput optionText={robot => robot.robotId} />
                            </ReferenceInput>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    );
};

const BulkEnableButton = ({ robotId, ioKeys, ...props }) => (
    <BulkUpdateButton
        {...props}
        label="Enable"
        data={{ robotId, ioKeys, enabled: true }}
        icon={<Visibility />}
    />
);

const BulkDisableButton = ({ robotId, ioKeys, ...props }) => {
    const [reasonType, setReasonType] = useState('OUT_OF_STOCK');
    const [reasonDescription, setReasonDescription] = useState('');
    return (<BulkUpdateWithConfirmButton
        {...props}
        label="Disable"
        data={{ 
            robotId,
            ioKeys,
            enabled: false, 
            resourceStatusInformation: {
                reasonType: reasonType,
                reasonDescription: reasonDescription
            }
        }}
        icon={<VisibilityOff/>}
        confirmContent={
            <DisabledReasonInput
                reasonType={reasonType}
                setReasonType={setReasonType}
                reasonDescription={reasonDescription}
                setReasonDescription={setReasonDescription}
            />
        }
    />);
}
const BulkActionButtons = (props) => {
    const { selectedIds } = props;
    const splitIds = selectedIds.map(ids => {
        const [robotId, ioKey] = ids.split('-');
        return { robotId, ioKey };
    });
    const ioKeys = splitIds.map(obj => obj.ioKey);
    const robotId = splitIds.length > 0 ? splitIds[0].robotId : null;

    return (
        <>
            <BulkCloneButton {...props} />
            <BulkEnableButton robotId={robotId} ioKeys={ioKeys} />
            <BulkDisableButton robotId={robotId} ioKeys={ioKeys} />
        </>
    );
};

export const RobotIOList = props => (
    <List {...props} syncWithLocation sort={{field:'', order:''}} 
          filters={filters} filterDefaultValues={{ robotId:0 }} pagination={false}>
        <Datagrid 
            isRowSelectable={record => !record.reversed}
            bulkActionButtons={<BulkActionButtons />}>
            <TextField source="ioKey" />
            <TextField source="ioControlKey" />
            <TextField source="ioName" />
            <FunctionField
                source="ioControlProperties"
                render={record => typeof record?.ioControlProperties !== 'string'
                    ? JSON.stringify(record?.ioControlProperties)
                    : record?.ioControlProperties || ''}
            />
            <BooleanField source="enabled" />
            <CupDispenserReset />
            <Operations label="Operations" />
            <DisabledReasonField label="Disabled reason" />
            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
            <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
            <ShowRobotIOButton />
            <EditRobotIOButton />
        </Datagrid>
    </List>
);

export const RobotIOShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="ioKey" />
            <TextField source="ioControlKey" />
            <TextField source="ioName" />
            <TextField source="ioControlProperties" />
            <TextField source="enabled" />
            <TextField source="deviceId" label="Device ID" />
            <TextField source="ioValue" />
            <Labeled label="State">
                <JsonField source="state" label="State" />
            </Labeled>
            <DisabledReasonField label="Disabled reason" />
            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
            <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
        </SimpleShowLayout>
    </Show>
);

export const RobotIOCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm >
                <TextInput source="robotId" />
                <ReferenceInput source="ioKey" reference="robot_ios_metadata" perPage={100}>
                    <SelectInput optionText="ioKey" fullWidth />
                </ReferenceInput>
                <TextInput source="ioControlKey" fullWidth />
                <JsonInput source="ioControlProperties" />
            </SimpleForm>
        </Create>
    );
};

export const RobotIOEdit = props => {
    const { record } = useEditController(props);
    if (!record || !record.ioKey || !record.ioControlKey) {
        return (
            <div />
        );
    }

    return (
        <Edit {...props}>
            <SimpleForm>
                <Grid container>
                    <Labeled label="Robot ID">
                        <TextField source="robotId" />
                    </Labeled>
                </Grid>
                <Grid container>
                    <TextInput source="existingIoKey" defaultValue={record.ioKey} disabled style={{ minWidth: '300px' }} />
                    &nbsp;&nbsp;<span style={{ display: 'flex', alignItems: 'center' }}>=&gt;</span>&nbsp;&nbsp;
                    <ReferenceInput source="newIoKey" reference="robot_ios_metadata" perPage={100} style={{ minWidth: '300px' }}>
                        <SelectInput optionText="ioKey" defaultValue={record.ioKey} />
                    </ReferenceInput>
                </Grid>
                <Grid container>
                    <TextInput source="existingIoControlKey" defaultValue={record.ioControlKey} disabled style={{ minWidth: '300px' }} />
                    &nbsp;&nbsp;<span style={{ display: 'flex', alignItems: 'center' }}>=&gt;</span>&nbsp;&nbsp;
                    <TextInput source="newIoControlKey" defaultValue={record.ioControlKey} style={{ minWidth: '300px' }} />
                </Grid>
                <JsonInput source="ioControlProperties" />
                <BooleanInput source="enabled" />
                <ExpandableContent buttonText="IoT Device Info">
                    <TextInput source="device.type" label="Device type" fullWidth />
                    <TextInput source="device.serial" label="Device serial" fullWidth />
                    <TextInput source="device.version" label="Device version" fullWidth />
                </ExpandableContent>
            </SimpleForm>
        </Edit>
    );
};
