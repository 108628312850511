import React from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    ReferenceInput,
    EditButton,
    Edit,
    DeleteButton,
    Create,
    SimpleForm,
    required,
    ReferenceField,
} from 'react-admin';

import AutocompleteInput from './components/BaseAutocompleteInput';

export const SupplyItemMetadataList = props => {
    return(
    <List {...props} pagination={false}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="supplyItemId" label="Supply Item Id"/>
            <TextField source="supplyItemName" label="Supply Item Name"/>
            <ReferenceField label="Target Option name" source="productAttributeOptionMapping.targetOption.optionId" reference="product_options_metadata" >
                <TextField source="optionName" />
            </ReferenceField>
            <ReferenceField label="Filtered Option name" source="productAttributeOptionMapping.filteredOption.optionId" reference="product_options_metadata" >
                <TextField source="optionName" />
            </ReferenceField>
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
)};

export const SupplyItemMetadataEdit = props => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="supplyItemName" label="Supply Item Name"/>
            </SimpleForm>
        </Edit>
    );
}

export const SupplyItemMetadataCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput required source="supplyItemId" label="Supply Item Id"/>
            <TextInput required source="supplyItemName" label="Supply Item Name"/>
            <ReferenceInput sort={{ field: '', order: 'ASC' }} source="productAttributeOptionMapping.filteredOption.optionId" reference="product_options_metadata">
                <AutocompleteInput
                    label="Filtered Option"
                    optionText="optionName"
                    defaultValue={""}
                    queryFields={['optionName']}
                    helperText="It's used to filter out the related products. In most cases, it's set as null."
                />
            </ReferenceInput>
            <ReferenceInput sort={{ field: '', order: 'ASC' }} source="productAttributeOptionMapping.targetOption.optionId" reference="product_options_metadata">
              <AutocompleteInput
                label="Target Option"
                optionText="optionName"
                validate={[required()]}
                queryFields={['optionName']}
                helperText="It's used to enable/disable the products' corresponding option." />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
