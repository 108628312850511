import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    TextField,
    ShowButton,
    NumberField,
    SimpleShowLayout,
    useRecordContext,
    ArrayField,
    BooleanField,
    FunctionField,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Divider } from '@mui/material';

const ShowRobotIOControlButton = () => {
    const record = useRecordContext();
    return <ShowButton record={{ ...record, id: record.ioControlKey }} />;
};

export const CommonRobotIOControlDataList = props => (
    <List {...props} sort={{ field: '', order: '' }} pagination={false}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="ioControlKey" />
            <TextField source="name" />
            <ArrayField source="ioControls" fullWidth>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="type" label="Type" />
                    <TextField source="topic" label="Topic" />
                    <TextField source="ioPin" label="IO Pin" />
                    <NumberField source="legacyId" label="Legacy ID" />
                </Datagrid>
            </ArrayField>
            <ShowRobotIOControlButton />
        </Datagrid>
    </List>
);

export const CommonRobotIOControlDataShow = props => {
    const location = useLocation();
    const paths = location.pathname.split('/');
    const id = paths[paths.length - 2];

    return (
        <Show {...props} id={id}>
            <SimpleShowLayout>
                <TextField source="robotId" />
                <TextField source="ioControlKey" />
                <TextField source="name" />
                <Divider>IO Controls</Divider>
                <ArrayField source="ioControls" fullWidth>
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="type" label="Type" />
                        <TextField source="topic" label="Topic" />
                        <TextField source="ioPin" label="IO Pin" />
                        <NumberField source="legacyId" label="Legacy ID" />
                        <NumberField source="onVal" label="On Value" />
                        <NumberField source="offVal" label="Off Value" />
                        <FunctionField source="onMsg" label="On Msg" render={record => {
                            if (!record.onMsg) {
                                return null;
                            }
                            return `x: ${record.onMsg?.x ?? 'null'}, 
                            y: ${record.onMsg?.y ?? 'null'}, 
                            z: ${record.onMsg?.z ?? 'null'}`;
                        }} />
                        <FunctionField source="offMsg" label="Off Msg" render={record => {
                            if (!record.offMsg) {
                                return null;
                            }
                            return `x: ${record.offMsg?.x ?? 'null'}, 
                            y: ${record.offMsg?.y ?? 'null'}, 
                            z: ${record.offMsg?.z ?? 'null'}`;
                        }} />
                        <NumberField source="reverseVal" label="Reverse Value" />
                        <NumberField source="motorCurrentThreshold" label="Motor Current Threshold" />
                        <NumberField source="timeTolerance" label="Time Tolerance" />
                        <BooleanField source="cancelOnPause" label="Cancel on Pause" />
                        <BooleanField source="cancelOnStop" label="Cancel on Stop" />
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
}
