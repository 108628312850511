import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    List,
    Show,
    Create,
    Datagrid,
    TextField,
    DateField,
    ArrayInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleFormIterator,
    SimpleShowLayout,
    ArrayField,
    BooleanField,
    ShowButton,
    DateTimeInput,
    NumberField,
    ReferenceField,
    TopToolbar,
    useNotify,
    useRefresh,
    useRecordContext,
    Confirm,
    useDataProvider,
    SimpleForm,
    useListContext,
    Filter,
    required,
    FunctionField,
    useShowController,
    Toolbar,
    SaveButton,
    NumberInput,
    Link,
} from 'react-admin';
import { Button, Box, Select, MenuItem, Grid, Dialog, DialogContent, DialogTitle, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from '@mui/material/Divider';
import Handlebars from "handlebars";
import AutocompleteInput from './components/BaseAutocompleteInput';

import { BluehillPagination } from './bluehillPagination';

const dateFilters = [
    <DateTimeInput key="startCreatedTimeString" label="Order Created Time From" source="startCreatedTimeString" alwaysOn />,
    <DateTimeInput key="endCreatedTimeString" label="Order Created Time To" source="endCreatedTimeString" alwaysOn />,
];

const filterTypes = {
    viewByStoreId: [
        <ReferenceInput
            key="storeId"
            label="Store Name"
            source="storeId"
            reference="stores"
            alwaysOn
            sort={{ field: 'storeName', order: 'ASC' }}
        >
            <AutocompleteInput
                source="storeId"
                optionText="storeName"
                queryFields={['storeName']}
                filterToQuery={searchText => ({ storeName: searchText })}
                validate={[required()]}
            />
        </ReferenceInput>,
        ...dateFilters,
    ],
    viewByOrderId: [
        <TextInput key="orderId" label="Order ID" source="orderId" alwaysOn />,
    ],
    viewByStoreIdAndCustomerName: [
        <TextInput key="customerName" label="Customer Name" source="customerName" alwaysOn />,
        <ReferenceInput key="storeId" label="Store Name" source="storeId" reference="stores" allowEmpty alwaysOn>
            <SelectInput optionText="storeName" />
        </ReferenceInput>,
        ...dateFilters,
    ],
    viewByStoreIdAndCustomerID: [
        <TextInput key="customerId" label="Customer ID" source="customerId" alwaysOn />,
        <ReferenceInput key="storeId" label="Store Name" source="storeId" reference="stores" allowEmpty alwaysOn>
            <SelectInput optionText="storeName" />
        </ReferenceInput>,
    ]
};

const useStyle = makeStyles({
    button: {
        backgroundColor:'#BEE0ED',
        "border-radius": '4px',
        textTransform :'none',
        "margin-right": '15px',
        "padding" : '10px',
        width : '150px'
    },
    cancelAllbutton: {
        backgroundColor:'#BEE0ED',
        "border-radius": '4px',
        textTransform :'none',
        "margin-right": '15px',
        "padding" : '10px',
        height: 48,
    },
    disabledButton: {
        backgroundColor:'#dbedf3',
        "border-radius": '4px',
        textTransform :'none',
        opacity: 0.5,
        "padding" : '10px',
        "margin-right": '15px',
        width : '150px',
        height: 48,
    },
    partialButton: {
        backgroundColor:'#BEE0ED',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        textTransform :'none',
        "margin-right": '20px',
        "padding" : '10px',
        height: 48,
    },
    ViewReceiptButton: {
        backgroundColor:'#BEE0ED',
        "border-radius": '4px',
        textTransform :'none',
        "margin-right": '15px',
        "padding" : '10px',
        height: 48,
    },
    CopyReceiptButton: {
        backgroundColor:'#BEE0ED',
        "border-radius": '4px',
        textTransform :'none',
        "margin-right": '15px',
        "padding" : '10px',
        height: 48,
        width: '200px',
    },
    resetButton: {
        backgroundColor:'#BEE0ED',
        padding : '7px'
    },
    priceInfo: {
        height: '20px',
        textAlign: 'right'
    },
    priceLabel: {
        height: '20px',
        textAlign: 'right'
    },
    refundPrice: {
        color: 'red',
    },
    original: {
        textDecoration: 'line-through',
        marginRight: '1em',
    },
    subTotal: {
        fontWeight: 'bold',
    },
    priceLabelContainer: {
        width: '250px'
    },
    priceContainer: {
        width: '60px'
    },
    grandTotalContainer: {
        width: '175px'
    },
    inputBox: {
        marginRight: -1,
        height: 48,
        width: 150,
        position: 'relative',
        bottom: 8,
    },
    emailTemplatePreview: {
        maxHeight: '20em',
        overflow: 'scroll',
        marginBottom: '2em',
        padding: '1em',
        border: '1px solid #ddd',
    },
});

export const RefundOrderButton = (props) => {
    const [open, setOpen] = React.useState(false); 
    const dataProvider = useDataProvider();
    const classes = useStyle();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    let buttonClass = classes.cancelAllbutton;
    let label = "Refund & Cancel Order"
    let status = false; 
    if (record.orderStatus === 'REFUNDED') {
        status = true;
        label = "Refunded"  
        buttonClass = classes.disabledButton
    }
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = ()  => {
        dataProvider.create(`orders_cancel_and_refund_excutions`, {
            id: props.orderId
        })
        .then(() => {
            refresh();
            notify('Refund issued');
        })
        .catch((e) => {
            notify(e.message);
        });
        setOpen(false);
    }
    return (
    <>
        <Button onClick={handleClick} disabled={status} className={buttonClass}>
            {label}        
        </Button>
        <Confirm
            isOpen={open}
            title="Refund"
            content="Are you sure you want to refund this?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </>
    );
};

export const RefundItemButton = (props) => {
    const [open, setOpen] = React.useState(false); 
    const dataProvider = useDataProvider();
    const classes = useStyle();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    let buttonClass = classes.button
    let label = "Refund item"
    let status = false; 
    if (record.itemStatus === 'REFUNDED') {
        status = true;
        label = "Refunded"  
        buttonClass = classes.disabledButton
    }
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = ()  => {
        dataProvider.create(`orders_partially_refund_executions`, {
            id: props.orderId,
            data: {refundItemIds: [record.itemId]},
        })
        .then(() => {
            refresh();
            notify('Refund issued');
        })
        .catch((e) => {
            notify(e.message);
        });
        setOpen(false);
    }
    return (
    <> 
        <Button onClick={handleClick} disabled={status} className={buttonClass}>
            {label}        
        </Button>
        <Confirm
            isOpen={open}
            title="Refund"
            content="Are you sure you want to refund this?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </>
    );
};

export const PartialRefundButton = (props) => {
    const [open, setOpen] = useState(false); 
    const dataProvider = useDataProvider();
    const classes = useStyle();
    const label = "Refund $" + props.refundAmount/100
    const refresh = useRefresh();
    const notify = useNotify();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        dataProvider.create('orders_partially_refund_executions',{
            id: props.orderId,
            data: {refundAmount: props.refundAmount}
        })
        .then(() => {
            refresh();
            notify('Refund issued');
        })
        .catch((e) => {
            notify(e.message);
        });
        setOpen(false);
    };  
    
    return (
    <> 
        <Button onClick={handleClick} className={classes.partialButton}>
            Refund
        </Button>
        <Confirm
            isOpen={open}
            title={label}
            content="Are you sure you want to refund this?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </>
    );
};

function useEmailTemplate(url) {
    const [template, setTemplate] = useState(() => () => '');

    useEffect(() => {
        fetch(url)
            .then((response) => response.text())
            .then((templateString) => {
                setTemplate(() => Handlebars.compile(templateString));
            });
    }, [url]);

    return useCallback(template, [template]);
}

function useEmailTemplateData(record) {
    const dataProvider = useDataProvider();
    const [data, setData] = useState(null);
    const notify = useNotify();

    useEffect(() => {
        if (!record?.id) {
            return;
        }
        dataProvider.getOne(`orders_email_receipt_info`, { id: record.id })
            .then(({ data }) => {
                setData(data);
            })
            .catch((e) => {
                notify(e.message);
            });
    }, [record?.id]);

    return data;
}

function SendReceiptButton() {
    const [open, setOpen] = useState(false);
    const [html, setHtml] = useState('');
    const classes = useStyle();
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const locale = useEmailTemplateData(record);

    const template = useEmailTemplate('/data/receipt-email-template.html');

    useEffect(() => {
        if (!template || !locale) {
            return;
        }
        const html = template(locale);
        setHtml(html);
    }, [template, locale]);

    const onSend = useCallback(({ emails }) => {
        dataProvider.create(`orders/${record.id}/operations/send_email_receipt/executions`, {
            data: {
                emails: emails.map(item => item.email),
            }
        })
            .then(() => {
                setOpen(false);
                refresh();
                notify('Email sent');
            })
            .catch((e) => {
                notify(e.message);
            });
    }, [record]);
    return (
        <>
            <Button onClick={() => setOpen(true)} className={classes.button}>Send Receipt</Button>
            <Dialog open={open} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle>Receipt Content</DialogTitle>
                <DialogContent>
                    <Box>
                        <div className={classes.emailTemplatePreview} dangerouslySetInnerHTML={{ __html: html }} />
                    </Box>
                    <Box>
                        <SimpleForm onSubmit={onSend} toolbar={
                            <Toolbar>
                                <SaveButton label="Send" />
                                <Button onClick={() => setOpen(false)}>Cancel</Button>
                            </Toolbar>
                        }>
                            <ArrayInput source="emails">
                                <SimpleFormIterator inline disableReordering>
                                    <TextInput source="email" type="email" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleForm>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export const ViewReceiptButton = (props) => {
    const dataProvider = useDataProvider();
    const classes = useStyle();
    const notify = useNotify();
    const refresh = useRefresh();
    const [receipt, setReceipt] = useState(null);
    const [openReceipt, setOpenReceipt] = useState(false);
    const handleClick = () => {
        dataProvider.getOne(`get_receipt`, {
            id: props.orderId,
        })
            .then((response) => {
                setReceipt(response.data);
                refresh();
                setOpenReceipt(true);
            })
            .catch((e) => {
                notify(e.message);
            });
    }
    useEffect(() => {
        if (openReceipt && receipt && receipt.receiptUrl) {
            window.open(receipt.receiptUrl, '_blank');
            setOpenReceipt(false);
        }
    }, [openReceipt, receipt]);

    return (
        <>
            <Button onClick={handleClick} className={classes.ViewReceiptButton}>
                View receipt
            </Button>
        </>
    );
};

export const CopyReceiptUrlButton = (props) => {
    const dataProvider = useDataProvider();
    const classes = useStyle();
    const notify = useNotify();
    const refresh = useRefresh();
    const [receipt, setReceipt] = useState(null);
    const [receiptUrlCopied, setReceiptUrlCopied] = useState(false);
    const handleClick = () => {
        dataProvider.getOne(`get_receipt`, {
            id: props.orderId,
        })
            .then((response) => {
                setReceipt(response.data);
                refresh();
                setReceiptUrlCopied(true);
            })
            .catch((e) => {
                notify(e.message);
            });
    }
    useEffect(() => {
        if (receiptUrlCopied && receipt && receipt.receiptUrl) {
            navigator.clipboard.writeText(receipt.receiptUrl)
                .then(() => {
                    notify('Receipt URL copied to clipboard');
                })
                .catch((e) => {
                    notify('Failed to copy receipt URL');
                });
        }
    }, [receiptUrlCopied, receipt]);

    return (
        <>
            <Button onClick={handleClick} className={classes.CopyReceiptButton}>
                Copy receipt Url
            </Button>
        </>
    );
};

const ListActions = (props) => {
    const { setFilters } = useListContext();
    const classes = useStyle();
    return (
    <TopToolbar>
         <Button onClick={() => setFilters({ "storeId":-1 })} label="Clear Filters" className={classes.resetButton} style={{ textTransform: "none" }}>
            <ClearIcon /> Reset filter values 
        </Button>
    </TopToolbar>
    );
};

function OrderListFilter(props) {
    const [filterType, setFilterType] = React.useState('viewByStoreId');
    const { setFilters } = useListContext();
    const handleFilterChange = (event) => {
        setFilters({ "storeId": -1 });
        setFilterType(event.target.value);  
    }
    const fields = filterTypes[filterType];

    return (
        <Box>
            <Select
                label="filter type"
                value={filterType}
                onChange={handleFilterChange}
                style={{ width:'250px' }}>
                <MenuItem value="viewByStoreId">View By Stores </MenuItem>
                <MenuItem value="viewByOrderId">View By Order ID</MenuItem>
                <MenuItem value="viewByStoreIdAndCustomerName">View By Customer Name</MenuItem>
                <MenuItem value="viewByStoreIdAndCustomerID">View By Customer ID</MenuItem>
            </Select>
            <Filter {...props}>
                {fields}
            </Filter>
        </Box>
    );
}

export const OrderList = props => {
    const now = new Date();
    return (
        <List {...props} syncWithLocation sort={{ field:'', order:'' }} perPage={50}
            filters={<OrderListFilter />} filterDefaultValues={{ storeId: -1, startCreatedTimeString: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0).toISOString() }} pagination={<BluehillPagination />} actions={<ListActions />}> 
            <Datagrid  bulkActionButtons={false}>
                <TextField label="Order Id" source="orderId" />
                <ReferenceField label="Store Name" link={false} source="storeId" reference="stores">
                    <TextField source="storeName" />
                </ReferenceField>
                <TextField label="Customer Name" source="customer.fullName" />
                <TextField label="Order Status" source="orderStatus" />
                <TextField label="Order Source" source="orderSource" />
                <NumberField label="Items Counts" source="items.length" />
                <FunctionField 
                    label="Total Price" 
                    render={record => formatMoneyString(record?.paymentInfo?.currencySymbol, record.priceInfo.grandTotal)}
                />
                <DateField source="createdTime" showTime />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

function formatMoneyString(currency = '$', value) {
    return `${currency}${(Boolean(value) ? value / 100 : 0).toFixed(2)}`;
}

function formatMoneyStringFromPriceString(currency = '$', priceString) {
    return `${currency}${priceString}`;
}

function msToMinutes(ms) {
    return Math.floor(ms / 60000);
}

function WaitingTimeField() {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [waitingInfo, setWaitingInfo] = useState(null);
    useEffect(() => {
        if (!['WAITING', 'PREPARING'].includes(record.orderStatus)) {
            return;
        }
        dataProvider.getOne(`orders_waiting_info`, { id: record.id })
            .then(({ data }) => {
                setWaitingInfo(data);
            });
    }, [record.id, record.orderStatus]);

    if (!['WAITING', 'PREPARING'].includes(record.orderStatus) || !waitingInfo) {
        return '-';
    }
    const min = msToMinutes(waitingInfo.estimatedMinWaitingTime);
    const max = msToMinutes(waitingInfo.estimatedMaxWaitingTime);
    return min === max ? `~ ${max} mins` : `${min} ~ ${max} mins`;
}

function OrderItemRecordLink() {
    const { record: order } = useShowController();
    const record = useRecordContext();
    return order.orderStatus === 'DONE' ? (
        <Link to={`/order_item_records/${record.id}/show`}>
            <TextField label="Item Id" source="id" />
        </Link>
    ) : <TextField label="Item Id" source="id" />;
}

export const OrderShow = props => {
    const classes = useStyle();
    const { record } = useShowController();
    const { id: orderId } = record ?? {};

    const toMoneyString = useCallback((value) => 
        typeof value === "number" ? formatMoneyString(record?.paymentInfo?.currencySymbol, value)
        : formatMoneyStringFromPriceString(record?.paymentInfo?.currencySymbol, value), [record?.paymentInfo?.currencySymbol]);
    const [refundAmount, setRefundAmount] = useState(0);
    const handleRefundAmount = (e) => {
        setRefundAmount(e.target.value);
    };

    if (!record) {
        return null;
    }

    return ( 
    <Show {...props}>
        <SimpleShowLayout>
            <Grid container direction="column">
                <Grid item>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>Order Information</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Order ID</TableCell>
                                    <TableCell sx={{ width: '35%' }}><TextField source="orderId" /></TableCell>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Order Status</TableCell>
                                    <TableCell sx={{ width: '35%' }}><TextField source="orderStatus" /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Order Source</TableCell>
                                    <TableCell sx={{ width: '35%' }}><TextField source="orderSource" /></TableCell>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Customer Name</TableCell>
                                    <TableCell sx={{ width: '35%' }}>
                                        {record.customer?.customerId
                                            ? (
                                                <Link to={encodeURI(`/customers/${record.customer.customerId}/show`)}>
                                                    <TextField label="" source="customer.fullName" />
                                                </Link>
                                            )
                                            : null}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Created Time</TableCell>
                                    <TableCell sx={{ width: '35%' }}><DateField source="createdTime" showTime /></TableCell>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Waiting time</TableCell>
                                    <TableCell sx={{ width: '35%' }}><WaitingTimeField label="Waiting Time" /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Schedule Type</TableCell>
                                    <TableCell sx={{ width: '35%' }}><TextField source="scheduleInfo.scheduleType" /></TableCell>
                                    <TableCell component="th" align="right" sx={{ width: '15%' }}>Schedule Time</TableCell>
                                    <TableCell sx={{ width: '35%' }}><DateField source="scheduleInfo.scheduledTime" showTime /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>Price Information</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Detail</TableCell>
                                    <TableCell align="right">Original</TableCell>
                                    <TableCell align="right">Discount</TableCell>
                                    <TableCell align="right" className={classes.subTotal}>Actual</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" sx={{ width: '25%' }}>Subtotal</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>
                                        <FunctionField render={record => toMoneyString(record.priceStringInfo.itemsSubTotal)} />
                                    </TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>{toMoneyString(`-${record.priceStringInfo.discount}` ?? "0.00")}</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>{toMoneyString(record.priceStringInfo.actualItemsSubTotal)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" sx={{ width: '25%' }}>Tax</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>
                                        <FunctionField render={record => toMoneyString(record.priceStringInfo.actualTax)} />
                                    </TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>-</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>{toMoneyString(record.priceStringInfo.actualTax)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" sx={{ width: '25%' }}>Tip</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>
                                        <FunctionField render={record => toMoneyString(record.priceStringInfo.absoluteTip)} />
                                    </TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>-</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>{toMoneyString(record.priceStringInfo.absoluteTip)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" sx={{ width: '25%' }}>Transaction Fee</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>
                                        <FunctionField render={record => toMoneyString(record.priceInfo.transactionFee)} />
                                    </TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>{toMoneyString(record.priceInfo.discountedTransactionFee - record.priceInfo.transactionFee)}</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>{toMoneyString(record.priceInfo.discountedTransactionFee)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} rowSpan={3} sx={{ width: '25%' }}></TableCell>
                                    <TableCell component="th" sx={{ width: '25%' }}>Applied Reward Points</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }} className={classes.refundPrice}>{(record.priceInfo.appliedRewardPoints ?? 0)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" className={classes.subTotal} sx={{ width: '25%' }}>Grand Total</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }}>
                                        <span className={classes.original}>{toMoneyString(record.priceInfo.originalGrandTotal)}</span>
                                        <span className={classes.subTotal}>{toMoneyString(record.priceInfo.grandTotal)}</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" sx={{ width: '25%' }}>Refund Total</TableCell>
                                    <TableCell align="right" sx={{ width: '25%' }} className={classes.refundPrice}>{toMoneyString(record.priceStringInfo.refundTotal)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <Box sx={{ fontSize: '0.875em', fontWeight: 'bold', lineHeight: '1.5rem', padding: '6px 16px', textAlign: 'center' }}>Items</Box>
                    <Divider />
                    <ArrayField label="Items" source="items">
                        <Datagrid bulkActionButtons={false}>
                            <OrderItemRecordLink />
                            <TextField label="Product Name" source="displayInfo.displayProductName" />
                            <TextField label="Product Options" source="displayInfo.displayOptionsSummary" />
                            <TextField label="Item Status" source="itemStatus" />
                            <BooleanField label="Made By Robot" source="madeByRobot" />
                            <FunctionField 
                            label="Original Price" 
                            render={record => toMoneyString(record.priceInfo.price)}
                            />
                            <FunctionField 
                            label="Actual Price" 
                            render={record => toMoneyString(record.priceInfo.actualPrice)}
                            />
                            <DateField source="createdTime" showTime />
                            <DateField source="updatedTime" showTime />
                            <RefundItemButton orderId={orderId}>Refund Item</RefundItemButton>
                        </Datagrid>
                    </ArrayField>
                </Grid>
                <Grid item>
                    <Box sx={{ fontSize: '0.875em', fontWeight: 'bold', lineHeight: '1.5rem', padding: '6px 16px', textAlign: 'center' }}>Combo Items</Box>
                    <Divider />
                    <ArrayField label="Combo Items" source="comboItems">
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="Combo ID" source="productComboId" />
                            <TextField label="Combo Name" source="productComboName" />

                            <ArrayField label="Items" source="items">
                                <Datagrid bulkActionButtons={false}>
                                    <TextField label="Item Id" source="id" />
                                    <TextField label="Product Name" source="displayInfo.displayProductName" />
                                    <TextField label="Product Options" source="displayInfo.displayOptionsSummary" />
                                    <TextField label="Item Status" source="itemStatus" />
                                    <BooleanField label="Made By Robot" source="madeByRobot" />
                                    <FunctionField 
                                        label="Original Price" 
                                        render={record => toMoneyString(record.priceInfo.price)}
                                    />
                                    <FunctionField 
                                        label="Actual Price" 
                                        render={record => toMoneyString(record.priceInfo.actualPrice)}
                                    />
                                    <DateField source="createdTime" showTime />
                                    <DateField source="updatedTime" showTime />
                                    <RefundItemButton orderId={orderId}>Refund Item</RefundItemButton>
                                </Datagrid>
                            </ArrayField>

                            <FunctionField 
                                label="Original Price"
                                render={record => toMoneyString(record.priceInfo.price)}
                            />
                            <FunctionField 
                                label="Actual Price"
                                render={record => toMoneyString(record.priceInfo.actualPrice)}
                            />
                            <DateField source="createdTime" showTime />
                            <DateField source="updatedTime" showTime />
                        </Datagrid>
                    </ArrayField>
                </Grid>
            </Grid>
        </SimpleShowLayout>
        <SimpleForm defaultValues={{ refundAmount: 0 }} toolbar={null}>
            <Box display={'flex'} width={'100%'} justifyContent="flex-end" >
                <NumberInput
                    className={classes.inputBox}
                    label="Refund Amount (0.00)"
                    source="refundAmount"
                    defaultValue={0}
                    format={value => value / 100}
                    parse={value => value * 100}
                    onChange={handleRefundAmount}
                />
                <PartialRefundButton refundAmount={refundAmount} orderId={orderId} />
                <RefundOrderButton orderId={orderId}>Refund Order</RefundOrderButton>
            </Box>
            <Box display={'flex'} width={'100%'} justifyContent="flex-end">
                <SendReceiptButton />
                <ViewReceiptButton orderId={orderId} />
                <CopyReceiptUrlButton orderId={orderId} />
            </Box>
        </SimpleForm>
    </Show>
)};
