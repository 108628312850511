import React, { useEffect, useState } from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    NumberField,
    NumberInput,
    EditButton,
    Button,
    ShowButton,
    Show,
    ArrayInput,
    useNotify,
    useShowController,
    TabbedShowLayout,
    Toolbar,
    SaveButton,
    useRedirect,
    Edit,
    SimpleFormIterator,
    ImageField,
    useListContext,
    CloneButton,
    FilterButton,
    ExportButton,
    TopToolbar,
    sanitizeListRestProps,
    TabbedForm,
    SimpleForm,
    BulkUpdateButton,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    Create,
    DeleteButton,
    FormDataConsumer,
    useEditController,
    useDataProvider,
    required,
    useRecordContext,
    useCreateController,
    ListBase,
    ArrayField,
    Labeled,
    ReferenceArrayInput,
    ReferenceField,
    AutocompleteInput,
    BulkUpdateWithConfirmButton,
    DateField,
    FunctionField,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { useFormContext } from 'react-hook-form';
import { Menu, MenuItem, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import AddIcon from '@mui/icons-material/Add';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AutocompleteArrayInput from './components/BaseAutocompleteArrayInput';
import BaseAutocompleteInput from './components/BaseAutocompleteInput';
import DisabledReasonInput from './components/DisabledReasonInput';

function optionRenderer(item) {
    return `${item.attributeName}: ${item.optionName}`;
}

const filters = [
    <ReferenceInput label="Store Name" source="storeId" reference="stores" alwaysOn>
        <SelectInput optionText="storeName" validate={[required()]} />
    </ReferenceInput>,
    <ReferenceArrayInput sort={{ field: 'optionId', order: 'ASC' }} label="Option" source="optionIds" reference="product_options_metadata" alwaysOn>
        <AutocompleteArrayInput optionText={optionRenderer} queryFields={['optionName', 'attributeName']} shouldRenderSuggestions={(key) => key?.length > 0} />
    </ReferenceArrayInput>,
    <BooleanInput label="In Stock" source="inStock" />,
    <BooleanInput label="Enabled" source="enabled" />
];

const reasonRenderer = (reasonStatusInformation) => {
    const reasonTitle = reasonStatusInformation?.reasonTitle;
    const reasonDescription = reasonStatusInformation?.reasonDescription;
    return reasonTitle ? (reasonDescription ? `${reasonTitle}: ${reasonDescription}` : reasonTitle) : '';
}

const DisabledReasonField = ({ label }) => {
    const record = useRecordContext();
    if (!record) return null;
    return <FunctionField label={label}
        render={record => reasonRenderer(record?.resourceStatusInformation)} />;
}

const BulkDisableButtonWithReason = ({label, icon, data, ...props}) => {
    const [reasonType, setReasonType] = useState('OUT_OF_STOCK');
    const [reasonDescription, setReasonDescription] = useState('');
    return (<BulkUpdateWithConfirmButton
        {...props}
        label={label}
        data={{ 
            ...data, 
            resourceStatusInformation: {
                reasonType: reasonType,
                reasonDescription: reasonDescription
            }
        }}
        icon={icon}
        confirmContent={
            <DisabledReasonInput
                reasonType={reasonType}
                setReasonType={setReasonType}
                reasonDescription={reasonDescription}
                setReasonDescription={setReasonDescription}
            />
        }
    />);
}

const BulkEnableButton = (props) => (
    <BulkUpdateButton
        {...props}
        label="Enable"
        data={{ enabled: true }}
        icon={<Visibility/>}
    />
);

const BulkDisableButton = (props) => (
    <BulkDisableButtonWithReason data={{ enabled: false }} label="Disable" icon={<VisibilityOff/>} />
);

const BulkInStockButton = (props) => (
    <BulkUpdateButton
        {...props}
        label="InStock"
        data={{ inStock: true }}
        icon={<BatteryChargingFullIcon/>}
    />
);

const BulkOutOfStockButton = (props) => (
    <BulkDisableButtonWithReason data={{ inStock: false }} label="OutOfStock" icon={<BatteryCharging20Icon/>} />
);

const BulkCloneButton = ({ filterValues, selectedIds, ...props }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    async function onSubmit({ storeId }) {
        try {
            await dataProvider.create('store_products_bulkclone_executions', {
                data: {
                    targetStoreId: storeId,
                    originalProductIds: selectedIds,
                }
            });
            notify('Products cloned!');
            setOpen(false);
        } catch (error) {
            console.error(error);
            notify('Products cloning failed!');
        }
    }
    return (
        <>
            <Button onClick={() => setOpen(true)} label="Clone">{<LibraryAddIcon />}</Button>
            <Dialog open={open} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle id="simple-dialog-title">Clone to new store</DialogTitle>
                <DialogContent>
                    <Create {...props}>
                        <SimpleForm onSubmit={onSubmit}>
                            <ReferenceInput sort={{field:'', order:'ASC'}} label="Target Store" source="storeId" reference="stores">
                                <SelectInput optionText={store => store.storeName} />
                            </ReferenceInput>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    );
};

const BulkActionButtons = (props) => {
    return (
        <>
            <BulkEnableButton />
            <BulkDisableButton />
            <BulkInStockButton />
            <BulkOutOfStockButton />
            <BulkCloneButton {...props} />
        </>
    );
};

const ListActions = (props) => {
    const {
        className,
        ...rest
    } = props;
    const { total, filterValues } = useListContext();

    const { storeId } = filterValues;
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CloneButton
                label="Create"
                icon={<AddIcon />}
                record={{
                    storeId,
                }}
            />
            <ExportButton disabled={total === 0} />
        </TopToolbar>
    );
};

const CloneRowButton = () => {
    const rowRecord = useRecordContext();
    return (
        <CloneButton 
            record={{ ...rowRecord, productId: rowRecord?.id }}
        />
    );
}

export const StoreProductList = props => (
    <List {...props} filters={filters} sort={{ field:'', order:'' }} actions={<ListActions />}
        filterDefaultValues={{ storeId: -1 }} pagination={false} >
        <Datagrid bulkActionButtons={<BulkActionButtons />}>
            <TextField label="Product Name" source="productName" />
            <TextField label="Base Price (￠)" source="basePrice" />
            <BooleanField label="In Stock" source="inStock" />
            <BooleanField label="Enabled" source="enabled" />
            <DisabledReasonField label="Disabled reason" />
            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
            <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
            <ShowButton />
            <EditButton />
            <CloneRowButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const SimpleFormIteratorRoot = styled(SimpleFormIterator)({
    paddingTop: '1em',
    '& .RaSimpleFormIterator-form': {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.12)',
    },
});

const Tooltips = ({ items, onClick }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <div>
            <AddButton label="Add Attribute" onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items?.map(e => (<MenuItem key={e.attributeId} onClick={() => {
                    handleClose();
                    onClick(e);
                }}>{e.attributeName}</MenuItem>))}
            </Menu>
        </div>
    );
}

const OptionSelect = (props) => {
    const { attributeProps, scopedFormData, formData, ...rest } = props;
    const dataProvider = useDataProvider();
    const [optionItems, setOptionItems] = React.useState([]);

    useEffect(() => {
        dataProvider.getList("product_options", {
            pagination: {},
            sort: {},
            filter: {
                attributeId: attributeProps.scopedFormData.attributeId
            },
        }).then(({ data }) => {
            if (data) {
                const result = (data.filter(attribute => !attributeProps?.scopedFormData?.attributeOptions?.find(option =>
                    option?.optionId === attribute?.optionId && scopedFormData?.optionId !== option?.optionId)) || []).sort((a, b) => a.optionName.localeCompare(b.optionName));
                setOptionItems(result);
            }
        }).catch(error => {
            console.log('error', error);
        });
    }, [attributeProps?.scopedFormData?.attributeId]);

    return (
        <AutocompleteInput
            validate={[required()]}
            label="Option"
            {...rest}
            choices={optionItems}
        />
    );
}

const validOption = (value, all, props) => {
    if (!value?.find(e => e?.defaultOption) && value?.filter(e => e?.defaultOption).lengh > 1) {
        return 'An attribute must have one and only one default option';
    }
    return undefined;
}

const AddButton = (props) => (
    <Button size="small" {...props}><AddIcon /></Button>
);

const AttributeForm = () => {
    const { record } = useEditController();

    const { setValue, watch } = useFormContext();
    const attributes = watch('attributes');

    const dataProvider = useDataProvider();
    const [addAttributeItem, setAddAttributeItem] = useState([]);

    const [attributesArray, setAttributesArray] = useState(null);

    const [attributesMetadata, setAttributesMetadata] = useState([]);

    useEffect(() => {
        getAttribute();
        getAllAttributes();
    }, []);

    const getAttribute = async () => {
        try {
            const { data } = await dataProvider.getOne("product_attributes", { id: record.id });
            if (data) {
                setAttributesArray([...data.attributes])
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const getAllAttributes = async () => {
        try {
            const { data } = await dataProvider.getList("product_attributes_metadata", {
                pagination: {},
                sort: {},
                filter: {},
            });
            if (data) {
                setAttributesMetadata(data)
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        const filterAddAttributeItem = elements => {
            setAddAttributeItem(elements?.filter(e => !attributes?.find(ele => ele?.attributeId === e?.attributeId)) || []);
        }
        if (attributesMetadata) {
            filterAddAttributeItem(Object.values(attributesMetadata) || [])
        }
    }, [attributesMetadata, attributes]);

    const onItemClick = (ele) => {
        setValue("attributes", attributes ? [...attributes, ele] : [ele]);
    }

    return attributesArray ? (
            <ArrayInput source="attributes" label="Attribute" defaultValue={attributesArray} fullWidth>
                <SimpleFormIterator fullWidth disableReordering getItemLabel={() => null} addButton={<Tooltips onClick={onItemClick} items={addAttributeItem}/>}>
                    <FormDataConsumer >
                        {({
                            formData,
                            scopedFormData,
                            getSource,
                        }) => attributesMetadata ? <TextInput
                                
                                source={getSource('attributeName')}
                                label="Attribute"
                            /> : null
                        }
                    </FormDataConsumer>
                    <FormDataConsumer fullWidth>
                        {(attributeProps) => {
                            return attributeProps?.scopedFormData?.attributeId
                                ? (
                                    <ArrayInput source={attributeProps.getSource('attributeOptions')} label="Options" validate={[validOption]} fullWidth>
                                        <SimpleFormIteratorRoot fullWidth disableReordering getItemLabel={() => null} addButton={<AddButton label="Add Option" />}>
                                            <FormDataConsumer >
                                                {({
                                                    getSource,
                                                    ...rest
                                                }) => {
                                                    return <OptionSelect
                                                        {...rest}
                                                        attributeProps={attributeProps}
                                                        label="Option"
                                                        source={getSource('optionId')}
                                                        optionValue='optionId'
                                                        optionText="optionName"
                                                    />
                                                }}
                                            </FormDataConsumer>
                                            <NumberInput  source="additionalPrice" label="Additional price (￠)" validate={[required()]} defaultValue={0}/>
                                            <NumberInput  source="discountedAdditionalPrice" label="Discounted additional price (￠)" defaultValue={null}/>
                                            <FormDataConsumer>
                                                {({
                                                    getSource,
                                                    ...rest
                                                }) => {
                                                    return <BooleanInput source={getSource('defaultOption')} label="Default option"
                                                        validate={[required()]}
                                                        defaultValue={!Boolean(attributeProps.scopedFormData?.attributeOptions?.find(e => e?.defaultOption))}
                                                    />
                                                }}
                                            </FormDataConsumer>
                                            <BooleanInput source="supportedByRobot" label="Supported by robot" validate={[required()]} defaultValue={true}/>
                                            <BooleanInput source="manualEnabled" label="Manual Enabled" defaultValue={true} validate={[required()]} />
                                        </SimpleFormIteratorRoot>
                                    </ArrayInput>)
                                : null;
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
    ) : null;
}

const CustomToolbar = props => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
}

export const StoreProductEdit = props => {
    const controllerProps = useEditController(props);

    const { save } = controllerProps;
    const dataProvider = useDataProvider();
    const location = useLocation();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSave = async (data) => {
        const { attributes, ...entity } = data;
        try {
            if (location.pathname.split('/').pop().includes('attributes')) {
                await dataProvider.update('product_attributes', {
                    id: data.id,
                    data: {
                        attributes: data.attributes
                    }
                });
                redirect('list', '/store_products');
                notify(`Element updated!`);
            } else {
                save(entity);
            }
        } catch (error) {
            notify(`Update failed!`);
        }
    }
    return (
        <Edit {...props} className="store-product-edit">
            <TabbedForm toolbar={<CustomToolbar />} onSubmit={onSave}>
                <TabbedForm.Tab label="Basic Info">
                    <Labeled>
                        <TextField source="id" label="ID" />
                    </Labeled>
                    <Labeled>
                        <TextField source="productName" label="Product Name" />
                    </Labeled>
                    <TextInput source="basePrice" label="Base Price (￠)"/>
                    <TextInput source="discountedBasePrice" label="Discounted Base Price (￠)" helperText="This field represents the final discounted price that will be displayed on the menu." />
                    <TextInput source="discountShortDescription" label="Discount Short Description"/>
                    <BooleanInput source="inStock"/>
                    <BooleanInput source="enabled"/>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Attributes" path="attributes">
                    <AttributeForm />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
}

function AttributesShow(props) {
    const { record } = useShowController(props);
    const listProps = {
        resource: 'product_attributes',
        filterDefaultValues: {
            id: record.id,
        }
    };

    return (
        <ListBase
            {...listProps}
            resource="product_attributes" filterDefaultValues={{
            id: record.id,
        }}>
            <Datagrid className="attributes-table" bulkActionButtons={false}>
                <TextField label="Attribute Name" source="attributeName" />
                <ArrayField label="Options" source="attributeOptions">
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="Option" source="optionName" />
                        <NumberField label="Additional Price" source="additionalPrice" />
                        <BooleanField label="Enabled" source="enabled" />
                        <BooleanField label="Supply Enabled" source="supplyEnabled" />
                        <BooleanField label="Manual Enabled" source="manualEnabled" />
                        <BooleanField label="Robot Capability Enabled" source="robotCapabilityEnabled" />
                        <BooleanField label="Default option" source="defaultOption" />
                    </Datagrid>
                </ArrayField>
            </Datagrid>
        </ListBase>
    );
}

export const StoreProductShow = props => {
    return (
        <Show {...props} className="store-product-show">
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Basic Info">
                    <TextField source="id" />
                    <TextField source="distinctProductId" />
                    <TextField source="name" />
                    <ReferenceField label="Product Name" source="distinctProductId" reference="product_metadata" link="show">
                        <TextField source="productName" />
                    </ReferenceField>
                    <BooleanField source="appliedToCoupons" />
                    <BooleanField source="enabled" />
                    <BooleanField source="inStock" />
                    <BooleanField source="available" />
                    <TextField source="basePrice" />
                    <TextField source="discountedBasePrice" label="Discounted Base Price (￠)"/>
                    <TextField source="discountShortDescription" label="Discount Short Description"/>
                    <TextField source="longDescription" />
                    <TextField source="shortDescription" />
                    <BooleanField source="supportedByRobot" />
                    <ImageField source="primaryImageThumbnailUrl" title="title" label="primary Image Thumbnail"/>
                    <ImageField source="primaryImageUrl" title="title" label="primary Image"/>
                    <DisabledReasonField label="Disabled reason" />
                    <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                    <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Attributes" path="attributes">
                    <AttributesShow {...props} />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const StoreProductCreate = props => {
    const controllerProps = useCreateController(props);
    const { save, record } = controllerProps;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSave = async (data) => {
        try {
            if(data?.productId !== undefined) {
                const {
                    inStock,
                    distinctProductId,
                    storeId,
                    enabled,
                    basePrice,
                    discountedBasePrice,
                    productId,
                } = data;
                await dataProvider.create('store_products_clone_executions', {
                    data: {
                        inStock,
                        distinctProductId,
                        storeId,
                        enabled,
                        basePrice,
                        discountedBasePrice,
                        productId,
                    }
                });
                redirect('list', '/store_products');
                notify('Element created!');
            } else {
                save(data, {
                    onSuccess: ({ id }) => {
                        redirect('edit', `/store_products/${id}/attributes`);
                    },
                });
            }
        } catch (error) {
            notify('Create element failed!');
        }
    }

    return (
        <Create {...props}>
            <SimpleForm toolbar={<CustomToolbar />} onSubmit={onSave}>
                <ReferenceInput source="distinctProductId" reference="product_metadata" sort={{field: 'productName', order: 'ASC'}} perPage={50}>
                    <BaseAutocompleteInput
                        label="Distinct Product Name"
                        optionText="productName"
                        optionValue="distinctProductId"
                        validate={[required()]}
                        disabled={record?.distinctProductId !== undefined}
                        queryFields={['id', 'productName']}
                        filterToQuery={(productName) => ({ productName })}
                        sorter={(a, b) => a.productName.localeCompare(b.productName)}
                    />
                </ReferenceInput>
                <ReferenceInput source="storeId" reference="stores" >
                    <SelectInput label="Store Name" optionText="storeName" validate={[required()]}/>
                </ReferenceInput>
                <BooleanInput source="inStock" />
                <BooleanInput source="enabled"/>
                <TextInput source="basePrice" label="Base Price (￠)" validate={[required()]}/>
                <TextInput source="discountedBasePrice" defaultValue={null}/>
                <TextInput source="discountShortDescription" label="Discount Short Description"/>
        </SimpleForm>
    </Create>
    );
}
