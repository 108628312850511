import * as React from "react";
import {
    Datagrid,
    BooleanField,
    TextField,
    EditButton,
    DeleteButton,
    Edit,
    Create,
    SimpleForm,
    BooleanInput,
    TextInput,
    SelectInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
import { JsonInput } from "react-admin-json-view";
import { BaseList } from './components/BaseList';

const ioTypeChoices = [
    { id: 'LIQUID_DISPENSER' },
];

const ioValueTypeChoices = [
    { id: 'BOOLEAN' },
    { id: 'FLOAT' },
];

const validateProperties = (value, allValues) => {
    if (allValues.ioType === 'LIQUID_DISPENSER') {
        if (!value || typeof value.normalizedViscosity !== 'number' || value.normalizedViscosity < 0 || value.normalizedViscosity > 1) {
            return '"LIQUID_DISPENSER" must include "normalizedViscosity" between 0 and 1, inclusive.';
        }
    }
    return undefined;
};

export const RobotIOMetadataList = props => (
    <BaseList {...props} syncWithLocation pagination={false} >
        <Datagrid bulkActionButtons={false}>
            <TextField source="ioKey" />
            <TextField source="defaultIoName" />
            <ReferenceArrayField label="IO Groups" source="groupIds" reference="robot_io_groups" >
                <SingleFieldList>
                    <ChipField source="groupName" />
                </SingleFieldList>
            </ReferenceArrayField>
            <BooleanField source="reversible" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </BaseList>
);

export const RobotIOMetadataCreate = props => (
    <Create {...props}>
        <SimpleForm >
            <TextInput source="ioKey" />
            <TextInput source="defaultIoName" />
            <SelectInput label="IO Type" source="ioType" optionText="id" optionValue="id" choices={ioTypeChoices}  />
            <SelectInput source="ioValueType" optionText="id" optionValue="id" choices={ioValueTypeChoices} />
            <TextInput source="promptMessage" />
            <ReferenceArrayInput sort={{ field: '', order: 'ASC' }} source="groupIds" reference="robot_io_groups">
                <AutocompleteArrayInput label="IO Groups" optionText={group => group.groupName} />
            </ReferenceArrayInput>
            <BooleanInput source="reversible" />
            <JsonInput source="properties" validate={validateProperties} />
            <JsonInput source="groups" />
        </SimpleForm>
    </Create>
);

export const RobotIOMetadataEdit = props => (
    <Edit {...props}>
        <SimpleForm >
            <TextInput source="ioKey" />
            <TextInput source="defaultIoName" />
            <SelectInput label="IO Type" source="ioType" optionText="id" optionValue="id" choices={ioTypeChoices} />
            <SelectInput source="ioValueType" optionText="id" optionValue="id" choices={ioValueTypeChoices} />
            <TextInput source="promptMessage" />
            <ReferenceArrayInput sort={{ field: '', order: 'ASC' }} source="groupIds" reference="robot_io_groups">
                <AutocompleteArrayInput label="IO Groups" optionText={group => group.groupName} />
            </ReferenceArrayInput>
            <BooleanInput source="reversible" />
            <JsonInput source="properties" validate={validateProperties} />
            <JsonInput source="groups" />
        </SimpleForm>
    </Edit>
);