import React, { useCallback, useMemo } from "react";
import {
  AutocompleteInput, useChoicesContext,
} from 'react-admin';
import Tooltip from '@mui/material/Tooltip';


const BaseAutocompleteInput = ({ queryFields = ['id', 'name'], shouldRenderSuggestions, sorter, ...props }) => {
  const { filterToQuery, optionValue, optionText } = props;
  const { allChoices, source, setFilters, selectedChoices } = useChoicesContext();
  const matchSuggestion = useCallback((value, choice) => {
    return value
      ? queryFields.some((field) => Boolean(choice && choice[field] && `${choice[field]}`.toLowerCase().includes(value.toLowerCase()))) : true;
  }, [queryFields]);

  const renderSuggestions = searchText => true;

  const setFilter = useCallback((v) => {
    if (typeof filterToQuery === 'function') {
      setFilters(filterToQuery(v));
      return;
    } else if (filterToQuery === true) {
      setFilters({ q: v });
    }
  }, [filterToQuery, setFilters]);

  const filteredChoices = useMemo(() => {
    const filtered = (optionValue ? allChoices?.filter(e => e[optionValue] != null) : allChoices);
    const sorted = typeof sorter === 'function' ? filtered.sort(sorter) : filtered;
    return sorted;
  }, [allChoices, optionValue]);

  return (
    <div>
      <AutocompleteInput
        choices={filteredChoices}
        source={source}
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
        matchSuggestion={matchSuggestion}
        shouldRenderSuggestions={shouldRenderSuggestions ?? renderSuggestions}
        noOptionsText="No item matched"
        setFilter={setFilter}
        {...props}
      />
    </div>
  );

  // return selectedChoices?.length ? (
  //   <Tooltip title={selectedChoices[0]?.[optionText]}>
  //     {content}
  //   </Tooltip>
  // ) : content;
}

export default BaseAutocompleteInput